<template>
  <div class="pb-2">
    <portal to="body-top">
      <h4 class="text-center font-weight-bolder mb-1">
        {{ $t('View Asset Inventory') }}{{ ` — ${inventory.sku} — ${inventory.name}` }}
      </h4>
    </portal>
    <!-- Serialized -->
    <l-table
      v-if="inventorySerializedAndNonSerializedVal"
      ref="lTableRef"
      :is-searchable="false"
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsInventoryItems"
      :create-page-url="{ name: 'home-inventory-item-create' }"
      :create-btn-dashed="true"
      :is-actions="true"
      :has-action="true"
      :trash-list-path-obj="trashListPath()"
      :trash-list-is-uri="true"
      :table-config-options="getTableConfigOptions()"
      :can-create="ACCESS_ABILITY_INVENTORY"
      @exportInventory="exportActions({
        btnTitle: 'Inventory',
        actionType: 'serialized_asset',
      })"
      @importInventory="importActions"
      @printAssetTagsInventory="printAssetTagsInventory"
    >
      <template #table-top-left-side>
        <tab-change
          :table-ref="$refs"
          :push-route="`/inventory/inventory-view/${routeID}`"
          @tabChangeEvent="onTabChange"
        />
      </template>
      <template #table-top-right-side-extras-prev>
        <table-actions :can-show-trash-list="ACCESS_ABILITY_INVENTORY" />
      </template>
      <template #cell(actions)="{ data }">
        <list-table-actions
          :data="data"
          :table-ref="$refs"
          :handle-open-modal="handleModal"
        />
      </template>
      <template #cell(warehouse)="{ data: {item} }">
        {{ getValueFromGivenObjectByKey(item, 'warehouse_location.warehouse.label', '—') }}
      </template>
      <template #cell(locations)="{ data: {item} }">
        <span class="d-block text-center">
          {{ getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—') }}
        </span>
      </template>
      <template #cell(sn_last_digits)="{ data }">
        {{ mapSerialNumber(data.item.serial_number) }}
      </template>
      <template #cell(asset_id)="{ data }">
        {{ inventorySerializedAndNonSerializedVal ? data.item.id : data.item.amount }}
      </template>
      <template #cell(active_since)="{ data }">
        {{ formatDate(data.item.created_at) }}
        <!--        convertTZ-->
      </template>
      <template #cell(current_state)="{ data: { item } }">

        <state-of-asset
          :data="item"
        />

      </template>
    </l-table>
    <!-- Serialized -->

    <!-- Non Serialized -->
    <l-table
      v-else
      ref="lTableRef"
      :is-searchable="false"
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsInventoryItemsNonSerialized"
      :create-page-url="{ name: 'home-inventory-item-create' }"
      :create-btn-dashed="true"
      :is-actions="true"
      :has-action="true"
      :is-delete="true"
      :trash-list-path-obj="trashListPath()"
      :trash-list-is-uri="true"
      :table-config-options="getTableConfigOptions()"
      :can-create="ACCESS_ABILITY_INVENTORY"
      @exportInventory="exportActions({
        btnTitle: 'Inventory',
        actionType: 'non_serialized_asset',
      })"
      @importInventory="importActions"
      @printAssetTagsInventory="printAssetTagsInventory"
    >
      <template #table-top-left-side>
        <tab-change
          :table-ref="$refs"
          :push-route="`/inventory/inventory-view/${routeID}`"
        />
      </template>
      <template #table-top-right-side-extras-prev>
        <table-actions :can-show-trash-list="ACCESS_ABILITY_INVENTORY" />
      </template>
      <template #cell(actions)="{ data }">
        <list-table-actions-non-serialized
          :data="data"
          :table-ref="$refs"
          :handle-open-modal="handleModal"
        />
      </template>
      <template #cell(asset_id)="{ data: { item } }">
        {{ inventorySerializedAndNonSerializedVal ? getValueFromGivenObjectByKey(item, 'id', '—') : getValueFromGivenObjectByKey(item, 'amount', '—') }}
      </template>
      <template #cell(locations)="{ data: { item } }">
        {{ getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—') }}
      </template>
      <template #cell(state)="{ data: { item } }">
        <state-of-asset
          :data="item"
        />
      </template>
    </l-table>
    <!-- Non Serialized -->

    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="back"
          >
            {{ $t('Back to List') }}
          </b-button>
        </div>
      </div>
    </portal>
    <export-c-s-v
      ref="export-c-s-v"
      :module-name="MODULE_NAME"
    />
    <import-c-s-v
      ref="import-c-s-v"
      :module-name="MODULE_NAME"
      @refetchTable="refetchData"
    />
    <Printer>
      <div
        id="barcode"
        hidden
      >
        <div
          v-for="(it,index) in inventoryItemsBarcodeList"
          :key="index"
        >
          <div v-html="it" />
        </div>
      </div>
    </Printer>

    <change-asset-to-damaged
      ref="change-asset-to-damaged"
    />
  </div>

</template>

<script>
import LTable from '@/views/components/LTable/LTable.vue'
import { VBTooltip, BButton } from 'bootstrap-vue'
import config from '@/views/main/inventory/inventoryConfig'
import { getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import ExportCSV from '@/views/components/Modals/ExportCSV.vue'
import ImportCSV from '@/views/components/Modals/ImportCSV.vue'
import Actions from '@/views/components/Actions.vue'
import Printer from '@/views/components/Printer/Printer.vue'
import { onUnmounted } from '@vue/composition-api'
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'
import store from '@/store'
import ChangeAssetToDamaged
from '@/views/main/inventory/inventory-view/components/ChangeAssetToDamaged/ChangeAssetToDamaged.vue'
import assetGeneralModule from '@/store/settings/inventory/asset-general'
import ListTableActions from './ListTableActions.vue'
import TabChange from './TabChange.vue'
import TableActions from './TableActions.vue'
import ListTableActionsNonSerialized from './ListTableActionsNonSerialized.vue'
import StateOfAsset from './StateOfAsset.vue'

export default {
  name: 'List',
  components: {
    ChangeAssetToDamaged,
    ExportCSV,
    ImportCSV,
    LTable,
    Printer,
    TabChange,
    Actions,
    BButton,
    TableActions,
    StateOfAsset,
    ListTableActions,
    ListTableActionsNonSerialized,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      chosenFiles: null,
      theDateFormat: JSON.parse(localStorage.getItem(jwtDefaultConfig.storageOrganizationDateFormat)),
      batchDeleteArr: [],
      tableCols: [],
      currentActiveQuery: this.$router.currentRoute.query.location ?? '/',
      routeID: this.$route.params.id,
    }
  },
  computed: {
    dateFormatLoaded() {
      return this.$store.state.listModule?.dateformatList.find(format => format.id === this.theDateFormat)
    },
    inventory() {
      return this.$store.state[this.MODULE_NAME].product
    },
    inventories() {
      return this.$store.state[this.MODULE_NAME].inventories
    },
    inventorySerializedAndNonSerializedVal() {
      return this.$store.state[this.MODULE_NAME].inventorySerializedAndNonSerializedVal
    },
    exportImportButtonItems() {
      return this.$store.state[this.MODULE_NAME].exportImportButtonItems
    },
    inventoryItemsBarcodeList() {
      return this.$store.state[this.MODULE_NAME].inventoryItemsBarcodeList
    },
  },
  mounted() {
    this.getPrintData()
    this.$store.dispatch(`${this.MODULE_ASSET_NAME}/getAssetGeneral`).then(res => {
      const { data } = res
      this.$store.commit(`${this.MODULE_ASSET_NAME}/SET_FORM`, data)
    })
  },
  created() {
    this.$store.dispatch('listModule/dateformatList')
  },
  methods: {
    handleModal() {
      this.$refs['change-asset-to-damaged'].show()
    },
    formatDate(date) {
      if (!date) return '-----'
      date = this.convertTZ(date)
      const dateFormat = this.dateFormatLoaded
      if (!dateFormat || !dateFormat.label) return '-----'
      const formattedDate = this.customFormatDate(date, dateFormat.label)
      return formattedDate
    },
    customFormatDate(date, format) {
      const parts = date.split(/[ -]/)

      const day = parts[0]
      const month = parts[1]
      const yearFull = parts[2]
      const yearShort = yearFull.slice(-2)
      const time = date.slice(-13)

      let formattedDate = format
        .replace('dd', day)
        .replace('MM', month)
        .replace('yyyy', yearFull)
        .replace('yy', yearShort)
        .replace('/', '-')
        .replace('/', '-')

      formattedDate = `${formattedDate} ${time}`

      return formattedDate
    },
    onTabChange() {
      this.getPrintData()
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    trashListPath() {
      return { path: `/inventory/inventory-view/${this.routeID}/trash-list`, query: this.paramsQuery() }
    },
    paramsQuery() {
      const queryString = { ...this.$route.query }
      delete queryString.active
      // return { query: queryString }
      return queryString
    },
    mapSerialNumber(number) {
      if (!number) return '---'

      if (number.length > 4) {
        return `*****${number.slice(-4)}`
      }
      return number
    },
    back() {
      this.$store.commit(`${this.MODULE_NAME}/GET_PRODUCT`, {})
      this.$store.commit(`${this.MODULE_NAME}/SET_IMPORT`, {})
      this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_ID`, null)
      this.$router.push({ name: 'home-inventory' })
    },
    filterTableColumns() {
      if (this.inventorySerializedAndNonSerializedVal) {
        return this.tableColumnsInventoryItems
      }
      return this.tableColumnsInventoryItemsNonSerialized
    },
    getPrintData() {
      const inventoryId = this.$route.params.id
      const { query } = this.$route
      const warehouseId = store.state.app.warehouse.id
      if (!inventoryId) return
      // eslint-disable-next-line no-nested-ternary
      const queryParams = query?.location === warehouseId?.toString() ? { product_id: inventoryId, warehouse_id: warehouseId } : query?.location === 'other' ? { other: 1, warehouse_id: warehouseId, product_id: inventoryId } : { product_id: inventoryId }
      this.$store.dispatch(`${this.MODULE_NAME}/getInventoryGeneratedBarCode`, queryParams).then(res => {
        const { data } = res.data
        this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_GENERATED_BARCODE_LIST`, data.html)
      })
    },

    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getInventoriesSubList`,
        data: {
          active: 1,
        },
      }
    },
    exportActions({ btnTitle, actionType }) {
      const { inventories } = this.$store.state[this.MODULE_NAME]
      const filterIds = inventories?.map(i => i.id)
      const { currentPage, perPage } = this.$refs.lTableRef.$data
      this.$store.commit(`${this.MODULE_NAME}/SET_EXPORT`, {
        title: btnTitle,
        actionType,
        query: {
          order_column: 'created_at',
          order_type: 'desc',
          page: currentPage,
          per_page: perPage,
          ids: filterIds,
        },
      })
      this.$refs['export-c-s-v'].show()
    },
    importActions({ btnTitle, actionType }) {
      this.$store.commit(`${this.MODULE_NAME}/SET_IMPORT`, {
        title: btnTitle,
        actionType,
      })
      this.$refs['import-c-s-v'].show()
    },
    printAssetTagsInventory() {
      this.$htmlToPaper('barcode')
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const MODULE_ASSET_NAME = 'asset-general'

    const {
      tableColumnsInventoryItems, ACCESS_ABILITY_INVENTORY, tableColumnsInventoryItemsNonSerialized, exportDataRequest,
    } = config()

    if (!store.hasModule(MODULE_ASSET_NAME)) store.registerModule(MODULE_ASSET_NAME, assetGeneralModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_ASSET_NAME)) store.unregisterModule(MODULE_ASSET_NAME)
    })
    return {
      MODULE_NAME,
      tableColumnsInventoryItems,
      ACCESS_ABILITY_INVENTORY,
      getValueFromGivenObjectByKey,
      tableColumnsInventoryItemsNonSerialized,
      exportDataRequest,
      MODULE_ASSET_NAME,
    }
  },
}
</script>

<style lang="scss" scoped>
.l-quote-btn {
  width: 32px;
  height: 32px;
  background-color: #646464;
  padding: 7px;
  border-radius: 5px;
}
</style>
