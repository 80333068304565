<template>
  <div>
    <b-button
      v-for="({name, query}, idx) in inventoryItemsSortTabs"
      :key="idx"
      :variant=" (typeof query === 'string' ? query : query.location) === currentActiveQuery ? 'secondary' : 'secondary-light'"
      style="margin: 0 6px 0 6px"
      @click="onTabChange(query)"
    >
      {{ name }}
    </b-button>
  </div>
</template>

<script>
import config from '@/views/main/inventory/inventoryConfig'
import { BButton } from 'bootstrap-vue'

export default {
  name: 'TabChange',
  components: {
    BButton,
  },
  props: {
    tableRef: {
      type: Object,
      required: true,
    },
    pushRoute: {
      type: String,
      required: false,
    },
    isTrashTable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentActiveQuery: this.$router.currentRoute.query.location ?? '/',
      routeID: this.$route.params.id,
    }
  },
  computed: {
    warehouse() {
      return this.$store.state.app.warehouse
    },
  },
  watch: {
    $route(newRoute) {
      if (newRoute.query.location) {
        this.currentActiveQuery = newRoute.query.location
      } else {
        this.currentActiveQuery = '/'
      }
    },
    warehouse(val) {
      if (!val) return
      const { id, label } = val

      if (+this.inventoryItemsSortTabs[0].query?.location !== id) {
        this.inventoryItemsSortTabs[0] = {
          ...this.inventoryItemsSortTabs[0],
          name: label,
          query: `${id}`,
        }

        this.onTabChange({ location: id })
      }
    },
  },
  mounted() {
    if (this.isTrashTable) {
      const query = {
        location: this.$route.params.location,
        current_location: this.$route.params.current_location,
        active: this.$route.params.active,
      }
      this.$router.push({ path: this.pushRoute ?? `/inventory/inventory-view/${this.routeID}`, query })
    }
  },
  methods: {
    onTabChange(query) {
      if (query !== '/') {
        this.$router.push({
          path: this.pushRoute ?? `/inventory/inventory-view/${this.routeID}`,
          query: { location: query.location, current_location: query.current_location },
        })
      } else {
        this.$router.push({ path: this.pushRoute ?? `/inventory/inventory-view/${this.routeID}`, query: {} })
      }
      this.tableRef.lTableRef.refetchData()
      this.$emit('tabChangeEvent', query)
    },
  },
  setup() {
    const { inventoryItemsSortTabs } = config()

    return { inventoryItemsSortTabs }
  },
}
</script>
