var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('portal',{attrs:{"to":"body-top"}},[_c('h4',{staticClass:"text-center font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t('View Asset Inventory'))+_vm._s((" — " + (_vm.inventory.sku) + " — " + (_vm.inventory.name)))+" ")])]),(_vm.inventorySerializedAndNonSerializedVal)?_c('l-table',{ref:"lTableRef",attrs:{"is-searchable":false,"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumnsInventoryItems,"create-page-url":{ name: 'home-inventory-item-create' },"create-btn-dashed":true,"is-actions":true,"has-action":true,"trash-list-path-obj":_vm.trashListPath(),"trash-list-is-uri":true,"table-config-options":_vm.getTableConfigOptions(),"can-create":_vm.ACCESS_ABILITY_INVENTORY},on:{"exportInventory":function($event){return _vm.exportActions({
      btnTitle: 'Inventory',
      actionType: 'serialized_asset',
    })},"importInventory":_vm.importActions,"printAssetTagsInventory":_vm.printAssetTagsInventory},scopedSlots:_vm._u([{key:"table-top-left-side",fn:function(){return [_c('tab-change',{attrs:{"table-ref":_vm.$refs,"push-route":("/inventory/inventory-view/" + _vm.routeID)},on:{"tabChangeEvent":_vm.onTabChange}})]},proxy:true},{key:"table-top-right-side-extras-prev",fn:function(){return [_c('table-actions',{attrs:{"can-show-trash-list":_vm.ACCESS_ABILITY_INVENTORY}})]},proxy:true},{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('list-table-actions',{attrs:{"data":data,"table-ref":_vm.$refs,"handle-open-modal":_vm.handleModal}})]}},{key:"cell(warehouse)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'warehouse_location.warehouse.label', '—'))+" ")]}},{key:"cell(locations)",fn:function(ref){
    var item = ref.data.item;
return [_c('span',{staticClass:"d-block text-center"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—'))+" ")])]}},{key:"cell(sn_last_digits)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.mapSerialNumber(data.item.serial_number))+" ")]}},{key:"cell(asset_id)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.inventorySerializedAndNonSerializedVal ? data.item.id : data.item.amount)+" ")]}},{key:"cell(active_since)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data.item.created_at))+" ")]}},{key:"cell(current_state)",fn:function(ref){
    var item = ref.data.item;
return [_c('state-of-asset',{attrs:{"data":item}})]}}],null,false,955627217)}):_c('l-table',{ref:"lTableRef",attrs:{"is-searchable":false,"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumnsInventoryItemsNonSerialized,"create-page-url":{ name: 'home-inventory-item-create' },"create-btn-dashed":true,"is-actions":true,"has-action":true,"is-delete":true,"trash-list-path-obj":_vm.trashListPath(),"trash-list-is-uri":true,"table-config-options":_vm.getTableConfigOptions(),"can-create":_vm.ACCESS_ABILITY_INVENTORY},on:{"exportInventory":function($event){return _vm.exportActions({
      btnTitle: 'Inventory',
      actionType: 'non_serialized_asset',
    })},"importInventory":_vm.importActions,"printAssetTagsInventory":_vm.printAssetTagsInventory},scopedSlots:_vm._u([{key:"table-top-left-side",fn:function(){return [_c('tab-change',{attrs:{"table-ref":_vm.$refs,"push-route":("/inventory/inventory-view/" + _vm.routeID)}})]},proxy:true},{key:"table-top-right-side-extras-prev",fn:function(){return [_c('table-actions',{attrs:{"can-show-trash-list":_vm.ACCESS_ABILITY_INVENTORY}})]},proxy:true},{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('list-table-actions-non-serialized',{attrs:{"data":data,"table-ref":_vm.$refs,"handle-open-modal":_vm.handleModal}})]}},{key:"cell(asset_id)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.inventorySerializedAndNonSerializedVal ? _vm.getValueFromGivenObjectByKey(item, 'id', '—') : _vm.getValueFromGivenObjectByKey(item, 'amount', '—'))+" ")]}},{key:"cell(locations)",fn:function(ref){
    var item = ref.data.item;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(item, 'warehouse_location.display', '—'))+" ")]}},{key:"cell(state)",fn:function(ref){
    var item = ref.data.item;
return [_c('state-of-asset',{attrs:{"data":item}})]}}])}),_c('portal',{attrs:{"to":"body-footer"}},[_c('div',{staticClass:"d-flex mt-2 pb-1 justify-content-between"},[_c('div',[_c('b-button',{staticClass:"cancelBtn font-medium-1 font-weight-bolder",attrs:{"variant":"outline-primary"},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t('Back to List'))+" ")])],1)])]),_c('export-c-s-v',{ref:"export-c-s-v",attrs:{"module-name":_vm.MODULE_NAME}}),_c('import-c-s-v',{ref:"import-c-s-v",attrs:{"module-name":_vm.MODULE_NAME},on:{"refetchTable":_vm.refetchData}}),_c('Printer',[_c('div',{attrs:{"id":"barcode","hidden":""}},_vm._l((_vm.inventoryItemsBarcodeList),function(it,index){return _c('div',{key:index},[_c('div',{domProps:{"innerHTML":_vm._s(it)}})])}),0)]),_c('change-asset-to-damaged',{ref:"change-asset-to-damaged"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }