<template>
  <div>
    <div class="d-flex justify-content-center w-100 mb-2">
      <b-skeleton
        style="margin-bottom: 0;"
        height="24px"
        width="35%"
      />
    </div>
    <div class="d-flex">
      <div
        v-for="{ id } in inventoryItemsSortTabs"
        :key="id"
        :variant="'secondary-light'"
        style="margin: 0 6px 0 6px; width: 100px;  height: 40px"
      >
        <b-skeleton
          style="margin-bottom: 0;"
          height="40px"
          width="100%"
        />
      </div>
    </div>
    <b-table-skeleton :fields="tableColumnsInventoryItems" />
  </div>
</template>

<script>
import config from '@/views/main/inventory/inventoryConfig'
import { BSkeleton } from 'bootstrap-vue'

export default {
  name: 'InventoryViewListSkeleton',
  components: {
    BSkeleton,
  },
  setup() {
    const { tableColumnsInventoryItems, inventoryItemsSortTabs } = config()

    return {
      tableColumnsInventoryItems,
      inventoryItemsSortTabs,
    }
  },
}
</script>
