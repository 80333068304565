<template>
  <div class="pb-2">
    <inventory-view-list-skeleton v-if="!inventory.id" />
    <list v-else />
  </div>
</template>

<script>
import config from '@/views/main/inventory/inventoryConfig'
import InventoryViewListSkeleton from './components/InventoryViewListSkeleton.vue'
import List from './components/List.vue'

export default {
  name: 'InventoryViewList',
  components: {
    List,
    InventoryViewListSkeleton,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    inventory() {
      return this.$store.state[this.MODULE_NAME].product
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'
    const { tableColumnsInventoryItems, inventoryItemsSortTabs } = config()

    return {
      MODULE_NAME,
      tableColumnsInventoryItems,
      inventoryItemsSortTabs,
    }
  },
}
</script>
